import { useState, useRef } from "react";
import axios from "axios";

export default function Contact() {
  const [emailValue, setEmailValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const contentRef = useRef(null);

  const contactToServer = async () => {
    try {
      if (!emailValue || !nameValue || !contentRef.current.innerText) {
        alert("Please fill in all the fields");
        return;
      }
      if (contentRef.current.innerText.length > 2000) {
        console.log(contentRef.current.innerText.length);
        alert("2000 character limit reached");
        return;
      }
      const res = await axios.post(
        "https://api.blocksquare.tech/api/v1/contact/email",
        {
          email: emailValue,
          name: nameValue,
          content: contentRef.current.innerText,
        }
      );
      console.log(res.data);
      alert("Successfully sent!");
      setEmailValue("");
      setNameValue("");
      contentRef.current.innerText = ""; // 내용 초기화
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={"main_contact_section" +" "+ "scroll_content"} id="contact">
      <div className="main_contact_input_box">
        <div className="main_contact_input_container">
          <h1>Contact us</h1>
          <div className="main_contact_line_with_circle">
            <div className="main_contact_circle"></div>
          </div>
          <div className="main_contact_input_info">
            <p>E-mail:</p>
            <input
              type="text"
              value={emailValue}
              onChange={(event) => setEmailValue(event.target.value)}
            />
          </div>
          <div className="main_contact_input_info">
            <p>Name / Company:</p>
            <input
              type="text"
              value={nameValue}
              onChange={(event) => setNameValue(event.target.value)}
            />
          </div>
          <div className="main_contact_input_content">
            <p>Contents</p>
            <div
              className="main_contact_input_content_text_area"
              contentEditable="true"
              ref={contentRef}
            ></div>
          </div>
          <button
            type="button"
            className="main_contact_btn"
            onClick={contactToServer}
          >
            SEND
          </button>
        </div>
      </div>
    </div>
  );
}
