import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

WebFont.load({
  google: {
    families: ['Noto Sans KR', 'Anton'],
  },
});

const Wrapper = styled.div`
  display: grid;
  position: relative;
  max-width: 100vw;
  height: 100vh;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 1fr);
  padding: 2rem;
  box-sizing: border-box;
  background-color: black;
  font-weight: bold;
  &._en {
    font-family:'Pretendard' !important;
  }
  &._jp {
    font-family: 'Shippori Mincho B1', serif;
  }
  
  &._cn {
    font-family: 'MsyhCN' !important;
  }
  
  &._ru {
    font-family: 'Pretendard' !important;
  }
  
  &._vn {
    font-family: 'Pretendard' !important;
  }

  @media only screen and (max-width: 768px) {
    display:block;
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem;
    height:fit-content;
  }
  @media screen and (max-width: 576px) {
  }
  @media only screen and (min-height: 2000px) {
    height:50vh;
  }
`;

const UpperLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 7;
  padding: 2rem;
  box-sizing: border-box;
  background-color: red;
  background: url("/assets/main_service_dl_img.jpg");
  background-size: cover;
  background-clip: content-box;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1024px) {
    background-size: 100% 100%;
    padding: 2px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const UpperRight = styled.div`
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 4;
    font-size: 1rem;
    background: url("/assets/main_service_dl_img.jpg");
    background-size: calc(100% + 10px) calc(105%);
    background-repeat: no-repeat;
    background-position: -5px -5px;
    height: 300px;
  }
`;

const UpperRightImg = styled.div`
  width: 12rem;
  height: 10rem;
  background: yellow;
  background: url("/assets/main_service_dl_img_cricle.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  @media screen and (max-width: 576px) {
    height:fit-content;
  }
`;

const TextBox = styled.div`
  font-size: 2rem;
  color: white;
  font-weight: bold;
  text-align: center;
  z-index: 100;
  line-height: 1.5;
  @media only screen and (min-width: 2000px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const LowerLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 7;
  grid-row-end: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  @media only screen and (max-width: 1024px) {
    font-size:2.8rem;
  }
  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 6;
    font-size: 2.5rem;
    text-align: center;
    line-height: 3rem;
  }
  @media screen and (max-width: 576px) {
    grid-row-start: 3;
    font-size:2rem;
    word-break:keep-all;
  }
`;

const LowerMiddle = styled.div`
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 7;
  grid-row-end: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 8;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 576px) {
    height:fit-content;
    grid-row-start: 5;
  }
`;
const LowerText = styled.div`
  font-size: 1.5rem;
  color: white;
  margin-top: 1.5rem;
  height: 90px;
  @media screen and (min-width: 2000px) {
    font-size: 2.2rem;
    line-height: 1.8rem;
    height: 140px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
    line-height: 1.8rem;
    height: 140px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
    text-align: center;
    height: auto;
  }
  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
`;

const LowerRight = styled.div`
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 7;
  grid-row-end: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  @media screen and (max-width: 1024px) {
    height: 400px;
  }
  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 10;
    font-size: 1.5rem;
    height:auto;
  }
  @media screen and (max-width: 576px) {
    height:fit-content;
    grid-row-start: 7;
  }
`;

function ServiceItemDL({ value, onClick }) {
  const { t, i18n } = useTranslation(['page']);

  let sectionClass = '';

  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }
  const isMobile = window.innerWidth <=576;
  if(i18n.language === 'jp') {
    if(isMobile){
      return (
        <Wrapper id="service_dl" className={sectionClass+" " + 'main_service_dl'}>
          <UpperLeft className='main_service_dl_upper_left'></UpperLeft>
          <UpperRight className='main_service_dl_upper_right'>
            <TextBox className='main_service_dl_text_box'>{t('service_dl1_1_mb')}<br></br>{t('service_dl1_2_mb')}</TextBox>
            <UpperRightImg className='main_service_dl_upper_right_img'></UpperRightImg>
          </UpperRight>
          <LowerLeft className='main_service_dl_lower_left'>
            {t('AutobotDeepLite_1')}
            <br></br>
            {t('AutobotDeepLite_2')}
            {/* <br></br> */}
            {t('AutobotDeepLite_3')}
          </LowerLeft>
          <LowerMiddle className='main_service_dl_lower_middle'>
            <LowerText className='main_service_dl_lower_text'>{t('service_dl2')}<br></br>{t('service_dl2_1_1_mb')}<br></br>{t('service_dl2_1_2_mb')}</LowerText>
            <LowerText className='main_service_dl_lower_text'>{t('service_dl3')}<br></br>{t('service_dl3_1')}</LowerText>
          </LowerMiddle>
          <LowerRight className='main_service_dl_lower_right'>
            <LowerText className='main_service_dl_lower_text'>{t('service_dl4')}</LowerText>
            <LowerText className='main_service_dl_lower_text'>{t('service_dl5')}{t('service_dl5_1')}</LowerText>
          </LowerRight>
        </Wrapper>
      );
    }
    return (
      <Wrapper id="service_dl" className={sectionClass+" " + 'main_service_dl'}>
        <UpperLeft className='main_service_dl_upper_left'></UpperLeft>
        <UpperRight className='main_service_dl_upper_right'>
          <TextBox className='main_service_dl_text_box'>{t('service_dl1')}</TextBox>
          <UpperRightImg className='main_service_dl_upper_right_img'></UpperRightImg>
        </UpperRight>
        <LowerLeft className='main_service_dl_lower_left'>
          {t('AutobotDeepLite_1')}
          <br></br>
          {t('AutobotDeepLite_2')}
          {/* <br></br> */}
          {t('AutobotDeepLite_3')}
        </LowerLeft>
        <LowerMiddle className='main_service_dl_lower_middle'>
          <LowerText className='main_service_dl_lower_text'>{t('service_dl2')}<br></br>{t('service_dl2_1')}</LowerText>
          <LowerText className='main_service_dl_lower_text'>{t('service_dl3')}<br></br>{t('service_dl3_1')}</LowerText>
        </LowerMiddle>
        <LowerRight className='main_service_dl_lower_right'>
          <LowerText className='main_service_dl_lower_text'>{t('service_dl4')}</LowerText>
          <LowerText className='main_service_dl_lower_text'>{t('service_dl5')}{t('service_dl5_1')}</LowerText>
        </LowerRight>
      </Wrapper>
    );
  } else if (i18n.language === 'ru') {
    return (
      <Wrapper id="service_dl" className={sectionClass}>
        <UpperLeft></UpperLeft>
        <UpperRight>
          <TextBox>{t('service_dl1')}<br></br>{t('service_dl1_1')}</TextBox>
          <UpperRightImg></UpperRightImg>
        </UpperRight>
        <LowerLeft>
          {t('AutobotDeepLite')}
        </LowerLeft>
        <LowerMiddle>
          <LowerText>{t('service_dl2')}<br></br>{t('service_dl2_1')}</LowerText>
          <LowerText>{t('service_dl3')}</LowerText>
        </LowerMiddle>
        <LowerRight>
          <LowerText>{t('service_dl4')}</LowerText>
          <LowerText>{t('service_dl5')}</LowerText>
        </LowerRight>
      </Wrapper>
    )
  }
  else {
  return (
      <Wrapper id="service_dl" className={sectionClass}>
        <UpperLeft></UpperLeft>
        <UpperRight>
          <TextBox>{t('service_dl1')}</TextBox>
          <UpperRightImg></UpperRightImg>
        </UpperRight>
        <LowerLeft>
          {t('AutobotDeepLite')}
        </LowerLeft>
        <LowerMiddle>
          <LowerText>{t('service_dl2')}</LowerText>
          <LowerText>{t('service_dl3')}</LowerText>
        </LowerMiddle>
        <LowerRight>
          <LowerText>{t('service_dl4')}</LowerText>
          <LowerText>{t('service_dl5')}</LowerText>
        </LowerRight>
      </Wrapper>
    )
  }
}

export default ServiceItemDL;
