import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

WebFont.load({
    google: {
        families: ['Bebas Neue','Noto Sans KR:500' ]
    }
})

export default function Roadmap(){

    const { t, i18n } = useTranslation(['page']);
    let sectionClass = '';
    if (i18n.language === 'en') {
        sectionClass += ' _en';
    }
    if (i18n.language === 'jp') {
        sectionClass += ' _jp';
    }else if (i18n.language === 'cn') {
        sectionClass += ' _cn'
    }else if (i18n.language === 'ru') {
        sectionClass += ' _ru'
    } else if (i18n.language === 'vn') {
        sectionClass += ' _vn'
    }

    const isMobile = window.innerWidth <=1024 || window.innerHeight >= 2000;

    const WebComponent = () => (
        <div className={'main_roadmap_section1_wrapper'+ sectionClass} id="roadmap">
        <h1>COMPANY HISTORY</h1>
        <div className='main_roadmap_section1_container'>
            <div className='main_roadmap_section1_content' style={{display:"none"}}>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img1.jpg'}></img>
                <div><p className='circle'>1</p><p>{t('OnebotArbitrage')}</p></div>
            </div>
            <div className='main_roadmap_section1_content'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img2.jpg'}></img>
                <div><p className='circle'>1</p><p>{t('OnebotScoreChart')}</p></div>
            </div>
            <div className='main_roadmap_section1_content'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img3.jpg'}></img>
                <div><p className='circle'>2</p><p>{t('AutobotDeepLite')}</p></div>
            </div>
            <div className='main_roadmap_section1_content'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img4.png'}></img>
                <div><p className='circle'>3</p><p>{t('AutobotDeepRevolution')}</p></div>
            </div>
            <div className='main_roadmap_section1_content'>
                <img src={process.env.PUBLIC_URL+'/assets/roadmap_goya_signal.png'}></img>
                <div><p className='circle'>4</p><p>{t('Alarm')}</p></div>
            </div>
            <div className='main_roadmap_section1_content'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img5.jpg'}></img>
                <p>What's Next?</p>
            </div>
        </div>
    </div> 
    )
    const MobileComponent = () => (
        <div className={'main_roadmap_section1_wrapper' + sectionClass} id="roadmap">
        <h1>COMPANY HISTORY</h1>
        <div className='main_roadmap_section1_container main_roadmap_section1_container_mb'>
            {/* <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img1.jpg'}></img>
                <p>{t('OnebotArbitrage')}</p>
            </div> */}
            <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img2.jpg'}></img>
                <p>{t('OnebotScoreChart')}</p>
            </div>
            <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img3.jpg'}></img>
                <p>{t('AutobotDeepLite')}</p>
            </div>
            <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img4.png'}></img>
                <p>{t('AutobotDeepRevolution')}</p>
            </div>
            <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/roadmap_goya_signal.png'}></img>
                <p>{t('Alarm')}</p>
            </div>
            <div className='main_roadmap_section1_content_mb'>
                <img src={process.env.PUBLIC_URL+'/assets/main_roadmap_img5.jpg'}></img>
                <p>What's Next?</p>
            </div>
        </div>
    </div> 
    )
  
    return (
        <>
            {isMobile ? <MobileComponent /> : <WebComponent />}
        </>
    )
}