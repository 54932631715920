import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WebFont from "webfontloader";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

WebFont.load({
  google: {
    families: ["Noto Sans KR", "Anton", "Montserrat:900"],
  },
});

const Wrapper = styled.div`
  max-width: 100vw;
  height: 100vh;
  position: relative;
  background: url("/assets/main_service_dr_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-size: calc(100% + 10px) calc(130% + 10px);
  background-position: -5px -5px;
  @media screen and (min-width: 2000px) {
    padding: 4rem;
    height: 100vh;
  }
  @media only screen and (max-width: 768px) {
    background-size: cover;
    background-position: 0, 0;
  }
  @media only screen and (min-height: 2000px) {
    height:50vh;
  }
`;

const TextBox = styled.div`
  position: absolute;
  height: fit-content;
  width: 50vw;
  background-color: white;
  //border: 1px solid black;
  bottom: 0;
  left: 4rem;
  padding: 2rem;
  box-sizing: border-box;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5rem 5rem 0 0;
  height:90vh;
    justify-content: space-evenly;
  &._en {
    font-family: "Pretendard" !important;
  }
  &._jp {
    font-family: "Shippori Mincho B1", serif;
  }

  &._cn {
    font-family: "MsyhCN" !important;
  }

  &._ru {
    font-family: "Pretendard" !important;
  }

  &._vn {
    font-family: "Pretendard" !important;
  }
  @media only screen and (min-width: 2000px) {
    padding: 4rem;
    height: 100vh;
  }
  @media only screen and (max-width: 1440px) {
    
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    left: 50%;
    bottom: 0;
    position: absolute;
    transform: translateX(-50%);
    padding: 1rem;
    padding-top: 2.5rem;
    background-color: rgba(255, 255, 255, 0.8);
    height: 90vh;
  }
  @media only screen and (max-width: 576px) {
    width:95vw;
    height:90vh;
  }
  @media only screen and (min-height: 2000px) {
    height:45vh;
  }
`;
const TextHeader = styled.div`
  text-align: left;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-contents: center;
  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 2.9rem;
    text-align: center;
    align-items: center;
  }
  @media only screen and (max-width: 576px) {
    font-size: 2.3rem;
    word-break: keep-all;
    overflow-wrap: anywhere;
  }
`;

const TextSub = styled.button`
  position: relative;
  background: linear-gradient(45deg, #00008b, #00003b);
  //background: #0000FF;
  color: white;
  width: 30vw;
  font-size: 1.5rem;
  margin-top: 3rem;
  padding: 0.7rem;
  box-sizing: border-box;
  border: 0;
  font-weight: 900;
  @media screen and (min-width: 2000px) {
    font-size: 2.5rem;
    width: 31vw;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.1rem;
    letter-spacing:1px;
    width: 40vw;
  }
  @media only screen and (max-width: 768px) {
    width: 70vw;
    border-radius: 1rem;
    font-size: 1.4rem;
    margin-top: 50px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 1rem;
    width:80vw;
    margin-top:20px;
  }
`;

const TextDesc = styled.div`
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (min-width: 2000px) {
    height: 80%;
  }
  @media screen and (max-width: 1024px) {
    height: 60%;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    justify-content: space-evenly;
  }
`;

const DescItem = styled.div`
  font-size: 1.5rem;
  margin: 20px 0;
  @media screen and (min-width: 2000px) {
    font-size: 2.6rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
    text-align: center;
    margin: 1rem 0;
  }
  @media only screen and (max-width: 576px) {
    font-size:1.1rem;
  } 
`;

function ServiceItemDR() {
  const { t, i18n } = useTranslation(["page"]);

  let sectionClass = "";

  if (i18n.language === "jp") {
    sectionClass += " _jp";
  } else if (i18n.language === "cn") {
    sectionClass += " _cn";
  } else if (i18n.language === "ru") {
    sectionClass += " _ru";
  } else if (i18n.language === "vn") {
    sectionClass += " _vn";
  }
  if (i18n.language === "jp") {
    return (
      <Wrapper id="service_dr">
        <TextBox className={sectionClass + ' ' + 'main_service_dr'}>
          <TextHeader className="main_service_dr_text_header">
            {t("AutobotDeepRevolution_1")}
            
            {t("AutobotDeepRevolution_2")}
            
            {t("AutobotDeepRevolution_3")}
            <TextSub className={sectionClass + ' ' + 'main_service_dr_text_sub'}>{t("service_dr1")}</TextSub>
          </TextHeader>
          <TextDesc>
            <DescItem className={sectionClass + ' ' + 'main_service_dr_desc_item'}>
              {t("service_dr2")}
              <br></br>
              {t("service_dr2_1")}
            </DescItem>
            <DescItem className={sectionClass + ' ' + 'main_service_dr_desc_item'}>{t("service_dr3")}</DescItem>
            <DescItem className={sectionClass + ' ' + 'main_service_dr_desc_item'}>{t("service_dr4")}</DescItem>
            <DescItem className={sectionClass + ' ' + 'main_service_dr_desc_item'}>{t("service_dr5")}</DescItem>
          </TextDesc>
        </TextBox>
      </Wrapper>
    );
  } else if (i18n.language === "ru") {
    return (
      <Wrapper id="service_dr">
        <TextBox className={sectionClass}>
          <TextHeader>
            {t("AutobotDeepRevolution")}
            <TextSub className={sectionClass}>{t("service_dr1")}</TextSub>
          </TextHeader>
          <TextDesc>
            <DescItem>{t("service_dr2")}</DescItem>
            <DescItem>{t("service_dr3")}</DescItem>
            <DescItem>
              {t("service_dr4")}
              <br></br>
              {t("service_dr4_1")}
            </DescItem>
            <DescItem>{t("service_dr5")}</DescItem>
          </TextDesc>
        </TextBox>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper id="service_dr">
        <TextBox className={sectionClass}>
          <TextHeader>
            {t("AutobotDeepRevolution")}
            <TextSub className={sectionClass}>{t("service_dr1")}</TextSub>
          </TextHeader>
          <TextDesc>
            <DescItem>{t("service_dr2")}</DescItem>
            <DescItem>{t("service_dr3")}</DescItem>
            <DescItem>{t("service_dr4")}</DescItem>
            <DescItem>{t("service_dr5")}</DescItem>
          </TextDesc>
        </TextBox>
      </Wrapper>
    );
  }
}

export default ServiceItemDR;
