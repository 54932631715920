import React, { useState, useEffect }from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

WebFont.load({
    google: {
        families: ['Noto Sans KR:900', 'Anton', 'Abril Fatface']
    }
})

const Wrapper = styled.div`
    display: grid;
    position: relative;
    max-width: 100vw;
    height: 100vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(9, 1fr);
    padding: 2rem;
    box-sizing: border-box;
    background-color: black;
    font-weight: bold;
    @media only screen and (max-width: 1024px) {
      padding: 1rem;
    }
  @media only screen and (max-width: 768px) {
    padding: 3rem 1rem 1rem;
    height:fit-content;
  }
  @media only screen and (min-height: 2000px) {
    height:50vh;
  }
`;

const UpperTitle = styled.div`
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    font-style: italic;
    @media only screen and (max-width: 1024px) {
      font-size: 2.2rem;
    }
      @media only screen and (max-width: 768px) {
        grid-row-start: 1;
        grid-row-end: 2;
        font-size: 1.5rem;
        grid-area: 1 / 1 / 1 / 11;
        margin-top: 1rem;
        box-sizing: border-box;
        grid-row-start: auto;
        padding:1rem;
      }
`

const UpperSub = styled.div`
  grid-column-start: 1;
  grid-column-end: 11;
  grid-row-start: 3;
  grid-row-end: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  font-size: 1.5rem;
  line-height:1.6em;
  text-align:center;
  @media only screen and (max-width: 1024px) {
    font-size:1.0rem;
    padding:10px 0;
  }
  @media only screen and (max-width: 768px) {
    // display: none;
    grid-row:1/2;
    font-size:1.0rem;
    padding:10px 0 30px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 0.9rem;
    word-break: keep-all;
    overflow-wrap: anywhere;
  }
`

const LowerTitleLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: italic;
  font-size: 1.7rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1.2rem;
  @media only screen and (max-width: 1024px) {
    font-size:1.3rem;
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 5;
    text-align: center;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    justify-content: space-around;
  }
  @media only screen and (max-width: 576px) {
    font-size:1.0rem;
  }
`
const LowerDescLeft = styled.div`
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1.5rem;
  box-sizing: border-box;
  line-height:1.4em;
  @media only screen and (max-width: 1024px) {
    padding:0.3rem
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 11;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
`;
const LowerDescRight = styled.div`
  grid-column-start: 8;
  grid-column-end: 11;
  grid-row-start: 4;
  grid-row-end: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1.5rem;
  box-sizing: border-box;
  line-height:1.4em;
  @media only screen and (max-width: 1024px) {
    padding:0.3rem
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 5;
    grid-column-end: 11;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
`

const LowerTitleRight = styled.div`
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: italic;
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  @media only screen and (max-width: 1024px) {
    justify-content: space-around;
    // width: 180px;
    font-size:1.3rem;
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 1;
    grid-column-end: 5;
    text-align: center;
    padding-left: 0.5rem;
    font-size: 1.5rem;
    justify-content: space-around;
  }
  @media only screen and (max-width: 576px) {
    font-size:1.0rem;
  }
`

const LowerLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 6;
  grid-row-end: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1;
  background: url("/assets/main_service_cr_img2.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
    background-size: 100% 100%;
  @media only screen and (max-width: 1024px) {
    font-size:2rem;
    text-align:center;
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 3;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 11;
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 576px) {
    font-size:1.2rem;
  }
`

const LowerRight = styled.div`
  grid-column-start: 6;
  grid-column-end: 11;
  grid-row-start: 6;
  grid-row-end: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  background: url("/assets/main_service_cr_pm.png");
  background-size: cover;
  height: 350px;
  background-size: 100% 100%;
  @media only screen and (max-width: 1024px) {
    font-size:2rem;
    text-align:center;
  }
  @media only screen and (max-width: 768px) {
    grid-row-start: 7;
    grid-row-end: 10;
    grid-column-start: 1;
    grid-column-end: 11;
    font-size: 2rem;
  }
  @media only screen and (max-width: 576px) {
    font-size:1.2rem;
  }
`

const YellowBar = styled.div`
  background-color: #DAA520;
  height: 4rem;
  width: 4px;
  margin-left:5px;
`

function ServiceItemCR() {

  const { t, i18n } = useTranslation(['page']);
  let sectionClass = '';
  if (i18n.language === 'en') {
    sectionClass += ' _en';
  }
  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }

    return(
        <Wrapper id='service_cr' className={sectionClass+' '+'main_service_cr' }>
            <UpperTitle className='main_service_cr_upper_title'>
            {t('OnebotScoreChart&RSI')}
            </UpperTitle>
            <UpperSub className='main_service_cr_upper_sub'>
            {t('service_cr1')}
            </UpperSub>
            <LowerTitleLeft className='main_service_cr_lower_title_left'>
            {t('OnebotScoreChart')}
                <YellowBar></YellowBar>
            </LowerTitleLeft>
            <LowerDescLeft className='main_service_cr_lower_desc_left'>
            {t('service_cr2')}
            </LowerDescLeft>
            <LowerTitleRight className='main_service_cr_lower_title_right'>
            {t('OnebotRSI')}
                <YellowBar></YellowBar>
            </LowerTitleRight>
            <LowerDescRight className='main_service_cr_lower_desc_right'>
            {t('service_cr3')}
            </LowerDescRight>
            <LowerLeft className='main_service_cr_lower_left'>
            {t('OnebotScoreChart')}
            </LowerLeft>
            <LowerRight className='main_service_cr_lower_right'>
              {t('OnebotRSI')}
            </LowerRight>
        </Wrapper>
    )
}

export default ServiceItemCR;