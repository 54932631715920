// import React, { useRef, useEffect,useState } from 'react';
import { useState,useRef,useEffect } from 'react';
import './css/main.css';
import './css/reset.css';
import Header from "./components/Header";
import Main from "./components/Main";
import Company from "./components/Company";
import Algorithm from "./components/Algorithm";
import Service from "./components/Service";
import Roadmap from "./components/Roadmap";
import CompanyNames from './components/CompanyNames';
import Contact from "./components/Contact";
import Footer from './components/Footer';
import ApkDownload from './components/ApkDownload';

import { BrowserRouter, useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(()=> {
    window.location.href = 'https://youthmeta.io/'
  },[])

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <div>
      {currentPath === '/download-china-apk' && <ApkDownload />}
      {currentPath === '/' && (
        <>
          <Header />
          <Main />
          <Company />
          <Algorithm />
          <Service />
          <Roadmap />
          <CompanyNames />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
