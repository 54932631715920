import React, { useRef, useEffect, useState } from 'react';
//다국어
import { useTranslation } from 'react-i18next'

export default function Main(){
  
  const { t, i18n } = useTranslation(['page']);
  let sectionClass = '';
  if (i18n.language === 'en') {
    sectionClass += ' _en';
  }
  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }

    const videoRef = useRef(null);
    const videoSources = [
      {src: process.env.PUBLIC_URL + '/assets/main_bg_video1.mp4', type: 'video/mp4'},
      { src: process.env.PUBLIC_URL + '/assets/main_bg_video2.mp4', type: 'video/mp4' },
      { src: process.env.PUBLIC_URL + '/assets/main_bg_video3.mp4', type: 'video/mp4' },
      { src: process.env.PUBLIC_URL + '/assets/main_bg_video4.mp4', type: 'video/mp4' }
    ];
    const currentVideoIndex = useRef(0);

    const handleVideoEnd = () => {
      if (currentVideoIndex.current < videoSources.length - 1) {
        currentVideoIndex.current++;
        
        // console.log(currentVideoIndex.current);
        // console.log(videoSources.length);
      } else {
        currentVideoIndex.current = 0;
        // console.log(currentVideoIndex.current);
        // console.log(videoSources.length);
      }
      videoRef.current.src = videoSources[currentVideoIndex.current].src;
      videoRef.current.type = videoSources[currentVideoIndex.current].type;
      videoRef.current.load();
      videoRef.current.play();
      // console.log(videoRef.current.autoplay);
      
      // console.log('video start');
    };
  
    useEffect(() => {
      videoRef.current.addEventListener('ended', handleVideoEnd);
      // console.log(videoRef.current);
      return () => {
        videoRef.current.removeEventListener('ended', handleVideoEnd);
      };
    }, []);

    

    const isMobile = window.innerWidth <= 768;

    if (i18n.language === 'jp') {
      if(isMobile) {
        return (<div className={"main_section1" + sectionClass +" "+ "scroll_content"}>
        <div className="video_container">
          <video ref={videoRef} loop muted autoPlay draggable="false" controls={false} playsInline={true}>
            <source src={process.env.PUBLIC_URL+'/assets/main_bg_video_mb3.mp4'} type='video/mp4' />
          </video>
          <div className='main_section1_text_box'>
              <h1>Global No.1 Fintech Service</h1>
              <p>{t('main_1_mb')} <br></br>{t('main_2_mb')}</p>
              <p>{t('main_2')}<br></br>{t('main_3')}</p>
          </div>
        </div>
      </div>
      )
      }else {
        return (<div className={"main_section1" + sectionClass +" "+ "scroll_content"}>
        <div className="video_container">
          <video ref={videoRef} muted autoPlay draggable="false" controls={false} playsInline={true}>
            <source src={videoSources[currentVideoIndex.current].src} type={videoSources[currentVideoIndex.current].type} />
          </video>
          <div className='main_section1_text_box'>
              <h1>Global No.1 Fintech Service</h1>
              <p>{t('main_1')}</p>
              <p>{t('main_2')} <br></br>{t('main_3')}</p>
          </div>
        </div>
      </div>)
      }
  }else {
      if(isMobile) {
        return (<div className={"main_section1" + sectionClass +" "+ "scroll_content"}>
        <div className="video_container">
          <video ref={videoRef} loop muted autoPlay draggable="false" controls={false} playsInline={true}>
          <source src={process.env.PUBLIC_URL+'/assets/main_bg_video_mb3.mp4'} type='video/mp4' />
          </video>
          <div className='main_section1_text_box'>
              <h1>Global No.1 Fintech Service</h1>
              <p>{t('main_1')}</p>
              <p>{t('main_2')} <br></br></p>
          </div>
        </div>
      </div>
      )
      }else {
        return (<div className={"main_section1" + sectionClass +" "+ "scroll_content"}>
        <div className="video_container">
          <video ref={videoRef} muted autoPlay draggable="false" controls={false} playsInline={true}>
            <source src={videoSources[currentVideoIndex.current].src} type={videoSources[currentVideoIndex.current].type} />
          </video>
          <div className='main_section1_text_box'>
              <h1>Global No.1 Fintech Service</h1>
              <p>{t('main_1')}</p>
              <p>{t('main_2')} <br></br></p>
          </div>
        </div>
      </div>
      )
    }
  }
}